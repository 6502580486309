
// 新增或修改成本中心接口文档地址：http://219.128.52.2:18765/doc.html?tdsourcetag=s_pctim_aiomsg#/haolv-consumer/t-us-cost-center-controller/addOrUpdateUsingPOST_2
const __request = require(`./__request/__request_contentType_json`);
const consumer_web_costCenter_addOrUpdate = (pParameter) => {
    if (!pParameter) pParameter = {};
    const p = {
        method: 'post',
        urlSuffix: '/consumer/web/costCenter/addOrUpdate',
        data: pParameter
    };
    return new Promise(function(resolve, reject) {
        resolve(__request(p))
    })
};
module.exports = consumer_web_costCenter_addOrUpdate;