import Q from 'q';
// 成本中心列表 
import consumer_web_costCenter_list from '@/lib/data-service/haolv-default/consumer_web_costCenter_list.js';
// 新增或修改成本中心 
import consumer_web_costCenter_addOrUpdate from '@/lib/data-service/haolv-default/consumer_web_costCenter_addOrUpdate.js';

export default {
  data () {
    const validateCode = (rule, value, callback) => {
      const regExp = /^[a-zA-Z0-9]+$/; // 定义只能包含字母和数字的正则表达式
      if (regExp.test(value) || !value) {
        callback();
      } else {
        callback(new Error("请输入数字或字母"));
      }
    };
    return {
      loading: false,
      form: {
        currentPage: 1,
        pageSize: 10,
        centerName: '',
        status: '',
        centerCode: ''
      },
      pager: {
        pageSizes: [10, 15, 20, 25, 30],
        total: 0,
      },
      allocation: {
        status: [
          { label: '全部', value: '' },
          { label: '正常', value: '1' },
          { label: '作废', value: '2' },
        ]
      },
      list: [],
      dialogVisible: false,
      dialogTitle: '',
      costForm: {
        id: '',
        centerName: '',
        describes: '',
        status: '',
        centerCode: ''
      },
      costRules: {
        centerCode: [
          { validator: validateCode, trigger: 'blur' }
        ],
        centerName: [
          { required: true, message: '请输入名称', trigger: 'blur' }
        ],
        describes: [
          { required: true, message: '请输入描述信息', trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    indexMethod (index) {
      return (this.form.currentPage - 1) * 10 + index + 1;
    },
    req_list (params, type) {
      const _this = this;
      _this.list = [];
      _this.loading = true;
      Q.when()
        .then(function () {
          return consumer_web_costCenter_list(params);
        })
        .then(function (res) {
          _this.list = res.datas.list;
          _this.pager.total = res.datas.totalCount
          if (type) document.documentElement.scrollTop = 0;
          _this.loading = false;
        })
    },
    req_add_cost_center (params) {
      return Q.when()
        .then(function () {
          return consumer_web_costCenter_addOrUpdate(params);
        })
    },
    search () {
      this.form.currentPage = 1;
      this.req_list(this.form);
    },
    change_search (val) {
      if (!val) {
        this.search();
      }
    },
    add_cost (row) {
      if (row.id) {
        this.dialogTitle = '编辑成本中心';
        const form = this.costForm;
        form.id = row.id;
        form.centerName = row.centerName;
        form.describes = row.describes;
        form.centerCode = row.centerCode;
        form.status = row.status;
      } else {
        this.dialogTitle = '新增成本中心';
        this.costForm.status = 1;
      }
      this.dialogVisible = true;
    },
    handleClose () {
      this.dialogVisible = false;
      this.dialogTitle = '';
      this.$refs.costForm.clearValidate();
      this.costForm = {
        id: '',
        centerName: '',
        centerCode: '',
        describes: '',
        status: '',
      };
    },
    commit_cost () {
      this.$refs.costForm.validate((valid) => {
        if (valid) {
          const _this = this;
          const message = this.costForm.id ? '修改成功' : '添加成功';
          Q.when()
            .then(function () {
              return _this.req_add_cost_center(_this.costForm);
            })
            .then(function (res) {
              _this.handleClose();
              _this.$message.success(message);
              _this.req_list(_this.form);
            })
        } else {
          return false;
        }
      })
    },
    change_status (id, status) {
      const message = status === 1 ? '启用' : status === 2 ? '作废' : status === 3 ? '删除' : '';
      this.$confirm( `确认${message}此帐号?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        closeOnClickModal: false,
        type: 'warning'
      }).then(() => {
        const _this = this;
        Q.when()
          .then(function () {
            const params = {
              id,
              status,
            }
            return _this.req_add_cost_center(params)
          })
          .then(function (res) {
            _this.$message.success(`${message}成功`);
            _this.req_list(_this.form);
          })
      }).catch(() => {

      })
    },
    change_currentPage (currentPage) {
      this.form.currentPage = currentPage;
      this.req_list(this.form);
    },
    change_pageSize (pageSize) {
      this.form.pageSize = pageSize;
      this.form.currentPage = 1;
      this.req_list(this.form);
    }
  },
  mounted () {
    this.req_list(this.form);
  },
  activated () {
    
  },
  deactivated () {

  }
}